import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class CommercialFloodInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Commercial Flood Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Unless your Louisiana based business has flood insurance, you’re faced with paying for recovery costs out of pocket, taking out a loan, or closing your doors permanently. Make sure your Louisiana based business is protected with Flood Insurance." }]}          
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Commercial Buildings Flooded" />
        <Callout
          title="Commercial Flood Insurance"
          content="Unless your Louisiana based business has flood insurance, you’re faced with three choices for your business: paying recovery costs out of your own pocket, taking out a loan (thus incurring debt), or closing your doors- maybe even permanently. Unfortunately, many businesses are forced to choose the third option. Make sure your Louisiana based business is protected with Flood Insurance."
        />
        <div className="site-body page-content">
          <h2>What Commercial Flood Insurance Usually Covers</h2>
          <p>
          <ul>
          <li>Electrical, plumbing systems, furnaces, water heaters, heat pumps and sump pumps.</li>
          <li>Refrigerators, cooking stoves and built-in appliances such as dishwashers.</li>
          <li>Permanently installed carpeting over an unfinished floor, paneling, wallboard, bookcases and cabinets. The damage must be directly caused by flood water. Cabinets that were not damaged by flood water are not covered, even if they match cabinets that were damaged by flood water.</li>
          <li>Foundation walls, anchorage systems and staircases attached to the building. There is an exclusion for “loss caused directly by earth movement even if the earth movement is caused by flood.” </li>
          <li>A detached garage, used for limited storage or parking. Up to 10 percent of the building coverage limit can be used, but will reduce the total amount of building coverage available.</li>
          <li>Fuel tanks and the fuel in them, solar energy equipment, and well water tanks.</li>
          <li>Furniture and electronic equipment.</li>
          <li>Portable and window air conditioners (easily moved or relocated), clothes washers and dryers.</li>
          <li>Certain valuable items such as original artwork and furs (up to $2,500).</li>
          <li>Food freezers and the food in them.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="There’s usually a 30-day waiting period for a flood insurance policy to start its coverage. However, some private policies offer a 15-day waiting period."
            source="Insurance Information Institute (I.I.I.)"
          />
          <h2>What Commercial Flood Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Damage caused by moisture, mildew or mold that could have been avoided by the property owner or which are not attributable to the flood.</li>
          <li>Damage caused by earth movement, even if the earth movement is caused by flood.</li>
          <li>Financial losses caused by business interruption.</li>
          <li>Loss of use or access of the insured property.</li>
          <li>Property and belongings outside of an insured building such as trees, plants, wells, septic systems, walks, decks, patios, fences, seawalls, hot tubs and swimming pools.</li>
          <li>Currency, precious metals and valuable papers, such as stock certificates.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Commercial Flood Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default CommercialFloodInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "commercial-flood-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-19.jpg" }) {
      ...mainBg
    }
  }
`;
